import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserService } from './browser.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  serverUrl = environment.serverUrl;
  customerId = environment.customerId;
  isBrowser = false;
  paymentServiceUrl = environment.paymentServiceUrl;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private route: Router,
    private localStorageService: LocalStorageService
  ) {
    browserService
      .getIsBrowser()
      .subscribe((isBrowser: any) => (this.isBrowser = isBrowser));
  }

  // subscribePlan(
  //   plan: any,
  //   rewardPoint: number | undefined,
  //   couponCode: string | undefined
  // ) {
  //   return new Promise((resolve, reject) => {
  //     const authToken = localStorage.getItem('authToken');
  //     const requestBody: any = { country: plan.country, token: authToken };
  //     if (rewardPoint) requestBody.redeemPoints = rewardPoint;
  //     if (couponCode) requestBody.coupon = couponCode;

  //     this.http
  //       .post(
  //         `${this.paymentServiceUrl}/payments/create/${plan._id}`,
  //         requestBody
  //       )
  //       .subscribe(
  //         (res: any) => {
  //           if (this.isBrowser && res?.url?.trim() != '') {
  //             window.location.href = res.url;
  //           }
  //           resolve(true);
  //         },
  //         (err: any) => {
  //           console.log(err);
  //           reject(err);
  //         }
  //       );
  //   });
  // }

  subscribePlan(
    plan: any,
    rewardPoint: number | undefined,
    couponCode: string | undefined
  ) {
    return new Promise((resolve, reject) => {
      const authToken = localStorage.getItem('authToken');
      const requestBody: any = { country: plan.country, platform: 'webapp' };
      if (rewardPoint) requestBody.redeemPoints = rewardPoint;
      if (couponCode) requestBody.coupon = couponCode;

      const planData = JSON.parse(
        this.localStorageService.getItem('plan-data')!
      );
      if (planData) {
        requestBody.topUpSubscriptionId = planData;
      }
      const headers = new HttpHeaders({
        Authorization: `Bearer ${authToken}`,
      });

      this.http
        .post(
          `${this.paymentServiceUrl}/payments/create/${plan._id}`,
          requestBody,
          { headers: headers }
        )
        .subscribe({
          next: (res: any) => {
            if (this.isBrowser && res?.url?.trim() != '') {
              if (res?.stripeIntent?.client_secret) {
                this.localStorageService.setItem(
                  'stripeKeys',
                  JSON.stringify({
                    stripeClientSecret: res?.stripeIntent?.client_secret,
                    paymentId: res?.paymentId,
                  })
                );
                this.route.navigate(['/payment-status']);
              } else {
                window.location.href = res.url;
              }
            }
            resolve(true);
          },
          error: (err: any) => {
            reject(err);
          },
        });
    });
  }

  addUpcomingPlanForSubscription(planData: any) {
    return this.http.post(`${this.serverUrl}/subscriptions`, {
      stripeProductId: planData.stripeProductId,
      country: planData.country,
      platform: 'webapp',
      transactionId: planData.transactionId,
    });
  }

  guestCheckout(planId: any, userDetails: any) {
    return this.http.post(
      `${this.paymentServiceUrl}/payments/checkout-guest/${planId}`,
      userDetails
    );
  }
}
