// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gtmId: 'GTM-NSTLF264',
  serverUrl: 'https://dev-api.esim.management.mobile.glowingbud.xyz',
  customerId: '661e2cdb06eb4eb82d6a44d1',
  s3BucketAssets: 'https://s3.amazonaws.com/assets.glowingbud.com',
  universalURL:
    'https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=',
  sp_key:
    'pk_test_51Molj3SCK3jr3By7jF6ShXIYV5iI5hUSXbFwxNhKzyAD60IaSCP6aLDynby4zbyjecVBr2yIyLkWjy3CeCbOeHjR00uLUmPG70',
  paymentServiceUrl: 'https://payment-microservice.glowingbud.xyz',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
