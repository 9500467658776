import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BrowserService } from './global/service';
import { isPlatformBrowser } from '@angular/common';
import { ConfigService } from './global/service/config.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'esim-management-portal-application-web-app';
  isBrowser = false;
  private readonly FIRST_VISIT_KEY = 'firstVisitDone';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private browserService: BrowserService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private gtmService: GoogleTagManagerService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    browserService.setIsBrowser(this.isBrowser);
  }

  ngOnInit(): void {
    this.configService.getConfigFromServer();
    this.translateService.addLangs(['en']);
    this.translateService.setDefaultLang('en');

    this.trackFirstVisit();
  }

  private trackFirstVisit() {
    const hasVisited = localStorage.getItem(this.FIRST_VISIT_KEY);

    if (!hasVisited) {
      this.sendGTMEvent();
      localStorage.setItem(this.FIRST_VISIT_KEY, 'true');
    }
  }

  private sendGTMEvent() {
    this.gtmService.pushTag({
      event: 'first_visit',
      pagePath: window.location.pathname,
    });
  }
}
