import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  EnvironmentInjector,
} from '@angular/core';

@Component({
  selector: 'app-dialog',
  // standalone: true,
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  // imports: [ SharedModule ]
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  constructor(private envInjector: EnvironmentInjector) {}
  @Output() closeEvent = new EventEmitter();
  @Input() template: any;
  @Input() context: any;
  @Input() cssClass: any;
  @Input() hasBackdrop: boolean | undefined = true;

  @ViewChild('backdrop', { read: ViewContainerRef })
  backdrop!: ViewContainerRef;
  @ViewChild('dialogContainer', { read: ViewContainerRef })
  container!: ViewContainerRef;
  @ViewChild('dialog', { read: ViewContainerRef }) dialog!: ViewContainerRef;
  componentRef: any;

  ngAfterViewInit(): void {
    // import('src/app/global/shared.module').then(({ SharedModule }) => {
    //   this.compiler.compileModuleAsync(SharedModule).then(moduleFactory => {
    // const moduleRef: any = moduleFactory.create(this.injector);
    this.componentRef = this.container.createComponent(this.template, {
      environmentInjector: this.envInjector,
    });
    if (this.context && Object.keys(this.context).length > 0) {
      Object.keys(this.context).forEach((key) => {
        this.componentRef.instance[key] = this.context[key];
      });
    }
    if (this.cssClass) {
      this.dialog.element.nativeElement.className += ' ' + this.cssClass;
    }
    //   })
    // });

    if (!this.hasBackdrop) {
      this.backdrop.element.nativeElement.remove();
      if (this.backdrop.element?.nativeElement?.parentElement?.className) {
        this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element.nativeElement.parentElement.className} top-spacing`;
      }
    } else if (
      !this.cssClass?.includes('top-spacing') &&
      this.backdrop?.element?.nativeElement?.parentElement?.className
    ) {
      this.backdrop.element.nativeElement.parentElement.className = `${this.backdrop.element?.nativeElement.parentElement.className} top-spacing`;
    }
  }

  ngOnDestroy(): void {}
}
